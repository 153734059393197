import '@styles/main.scss';
import menu from './components/menu';

class App {
    constructor() {
        menu();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
